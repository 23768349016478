import React from 'react';
import styles from './page-top.module.scss';
import { ReactComponent as LogoOneRun } from '../../assets/images/logo-one-run.svg';
import Timer from '../timer/timer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Textfit } from 'react-textfit';

const LANGUAGES = [
  { code: 'ru', label: 'Ру' },
  { code: 'en', label: 'En' },
  { code: 'es', label: 'Es' },
  { code: 'pt', label: 'Pt' },
  { code: 'fr', label: 'Fr' },
  { code: 'zh', label: '中国' }
];

function PageTop({ country }) {
  const { i18n, t } = useTranslation();
  const lang = i18n?.resolvedLanguage;

  const changeLang = (evt) => {
    const { value } = evt.target;
    i18next.changeLanguage(value);
    document.documentElement.lang = i18n.language;
  };

  return (
    <section className={`${styles.pageTop} ${country ? styles.pageTopCountry : ''}`}>
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.logos}>
            <LogoOneRun className={styles.logo} width={128} height={70} />
            {/*<LogoZabeg className={styles.logoZabeg} />*/}
          </div>

          <div className={styles.timerWrap}>
            <Timer />
          </div>

          {!country && (
            <div className={styles.langWrap}>
              {LANGUAGES.map(({ code, label }) => (
                <React.Fragment key={code}>
                  <input
                    className={`${styles.inputLang} visually-hidden`}
                    type="radio"
                    value={code}
                    name="language"
                    id={`${code}Lang`}
                    onChange={changeLang}
                    checked={lang === code}
                  />
                  <label htmlFor={`${code}Lang`} className={styles.headerLabel}>
                    {label}
                  </label>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        <div className={styles.content}>
          {/*<a className={styles.partnerLink} href="https://www.psbank.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*  <span className={styles.partnerLabel}>Генеральный спонсор</span>*/}
          {/*  <img className={styles.partnerLogo} src={logoPsb} width="257" height="153" alt="Спонсор ПСБ"/>*/}
          {/*</a>*/}

          <div className={styles.dateWrap}>
            <p className={styles.date}>{t("pageTop.date")}</p>

            <p className={styles.description}>
              {t("pageTop.description")}
            </p>
          </div>

          <div className={styles.sloganWrapper}>
            <Textfit
              mode="single"
              forceSingleModeWidth={true}
              min={20}
              max={1000}
              throttle={30}
              className={styles.slogan}
            >
              {t("pageTop.mainTitlePart1")} <span className={styles.focus}>{t("pageTop.mainTitlePart2")}</span>{t("pageTop.mainTitlePart3")}
            </Textfit>
          </div>

          <a className={styles.anchor} href="#formats">{country ? 'Принять участие' : t("pageTop.btn")}</a>
        </div>
      </div>
    </section>
  );
}

export default PageTop;
