import styles from './countries.module.scss';
import Container from '../app/container/container';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { times } from '../../utils/common';

function Countries() {
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(-1);
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    fetch('countries.json')
      .then((r) => r.json())
      .then((r) => {
        setCountries(r.values);
      })
      .catch((err) => console.error(err));
  };

  const handleMouseEnterItem = (i) => {
    if (!isTablet) {
      setHoveredItem(i);
    }
  };

  const handleMouseLeaveItem = () => {
    if (!isTablet) {
      setHoveredItem(-1);
    }
  };

  const getCountryImagePath = (countryKey) => {
    return `/images/countries/${countryKey}.webp`;
  };

  return (
    <section className={styles.formats} id="formats">
      <Container>
        <h2 className={styles.title}>{t("countriesComponent.title")}<span className={styles.red}>{t("countriesComponent.title2")}</span></h2>
        <ul className={styles.list}>
          {countries?.map((format, index) => (
            <li
              className={`
                ${styles.item} 
                ${(hoveredItem >= 0 && hoveredItem !== index) ? styles.itemHover : ''} 
                ${(hoveredItem >= 0 && hoveredItem === index) ? styles.itemHoverActive : ''}
              `}
              key={format.country}
              onMouseEnter={() => handleMouseEnterItem(index)}
              onMouseLeave={handleMouseLeaveItem}
            >
              <div className={styles.itemContent}>
                <div className={styles.imgWrap}>
                  <img className={styles.img} src={getCountryImagePath(format.country)} alt={t(`countries.${format.country}`)} />
                </div>
                <div className={`${styles.inner} ${!format.active ? styles.innerSoon : ''}`}>
                  <p className={styles.countryWrap}>
                    {/*<span className={styles.label}>{t("countriesComponentcountryLabel")}:</span>*/}
                    <span className={`${styles.value} ${styles.valueCountry}`}>{t(`countries.${format.country}`)}</span>
                  </p>
                  <p className={styles.cityWrap}>
                    <span className={styles.label}>{t("countriesComponent.cityLabel")}</span>
                    <span className={`${styles.value} ${styles.valueCity}`}>{t(`cities.${format.country}`)}</span>
                  </p>
                  {/* {format.startTime !== 0 && (
                    <p className={styles.dateWrap}>
                      <span className={styles.label}>{t("countriesComponentdateLabel")}:</span>
                      <span className={styles.value}>{times.getNumberShortDate(format.startTime)}</span>
                    </p>
                  )} */}
                </div>
              </div>
              <Link
                to={format.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.btn} ${format.link?.length === 0 ? styles.linkDisabled : ''}`}
              >
                {format.link?.length === 0 ? (
                  t("countriesComponent.btnSoon")
                ) : (
                  t("countriesComponent.btn")
                )}
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}

export default Countries;
