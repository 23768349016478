import { useState } from 'react';
import item from '../../assets/images/startPack.webp';
import item2 from '../../assets/images/start-pack2.webp';
import item3 from '../../assets/images/start-pack3.webp';
import item4 from '../../assets/images/start-pack4.webp';
import item5 from '../../assets/images/start-pack5.webp';
import bottom from '../../assets/images/bottom.webp';
import top from '../../assets/images/top.webp';
import styles from './StartPack.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

function StartPack() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState(isMobile ? 2 : 0);

  const words = [t("startPack.item1"), t("startPack.item2"), t("startPack.item3"), t("startPack.item4"), t("startPack.item5")];

  const startPacs = [
    {
      id: 0,
      label: t("startPack.item1"),
      img: {
        src: item,
        width: 256,
        height: 342,
        isSoon: true,
      },
    },
    {
      id: 1,
      label: t("startPack.item2"),
      img: {
        src: item2,
        width: 206,
        height: 209,
        isSoon: true,
      },
    },
    {
      id: 2,
      label: t("startPack.item3"),
      img: {
        src: item3,
        width: 336,
        height: 341,
        isSoon: true,
      },
    },
    {
      id: 3,
      label: t("startPack.item4"),
      img: {
        src: item4,
        width: 300,
        height: 215,
        isSoon: false,
      },
    },
    {
      id: 4,
      label: t("startPack.item5"),
      img: {
        src: item5,
        width: 336,
        height: 341,
        isSoon: false,
      },
    },
  ];

  const visibleRange = 3;
  const start = Math.max(activeItem - Math.floor(visibleRange / 2), 0);
  const end = Math.min(start + visibleRange, words.length);

  const handleUp = () => {
    if (activeItem > 0) {
      setActiveItem((prev) => prev - 1);
    }
  };

  const handleDown = () => {
    if (activeItem < words.length - 1) {
      setActiveItem((prev) => prev + 1);
    }
  };

  return (
    <section className={styles.startPack} id="startPack">
      <div className={styles.inner}>
        <h2 className={styles.title}>{t("startPack.title")}<span className={styles.red}>{t("startPack.title2")}</span></h2>
        <div className={styles.wrapDesc}>
          {isMobile ? <p className={styles.desc}>{t("startPack.desc")}</p> : <p className={styles.desc}>{t("startPack.desc2")}</p>}
        </div>
        <div className={styles.itemsPreviewWrap}>
          <div className={styles.itemsCard}>
            {isMobile && <img onClick={handleUp} disabled={activeItem === 0} className={styles.topButton} src={top} width={32} height={32} />}
            <ul className={styles.items}>

              {!isMobile && startPacs.map((item) => (
                <li
                  className={`${styles.item} ${item.id === activeItem ? styles.itemActive : ''}`}
                  onClick={() => setActiveItem(item.id)}
                  key={item.id}
                >
                  {item.label}
                </li>
              ))}

              {isMobile && words.slice(start, end).map((word, index) => {
                const globalIndex = start + index;
                return (
                  <li
                    key={globalIndex} onClick={() => setActiveItem(globalIndex)}
                    className={`${styles.item} ${globalIndex === activeItem ? styles.itemActive : ''}`}>
                    {word}
                  </li>
                );
              })}

            </ul>
            {isMobile && <img onClick={handleDown} disabled={activeItem === words.length - 1} className={styles.bottomButton} src={bottom} width={32} height={32} />}
            {!isMobile && <p className={styles.footnote}>{t("startPack.text")}</p>}
          </div>

          <div className={styles.previewCard}>
            {startPacs.map((item) => item.id === activeItem && (
              <>
                <div style={{position: "relative"}}>
                  <img
                    className={`${styles.itemImg} lazyload`}
                    src={item.img.src}
                    width={item.img.width}
                    height={item.img.height}
                    alt={item.img.label}
                    key={item.id}
                  />
                </div>

                {item.img.isSoon && <div className={styles.dis}>{t("startPack.text2")}</div>}
              </>
            ))}
          </div>
        </div>
        {isMobile && <div className={styles.foot}><p className={styles.footnote}>{t("startPack.text")}</p></div>}
      </div>
    </section>
  );
}

export default StartPack;
