import styles from './main-screen.module.scss';
import PageTop from '../../components/page-top/page-top';
import Countries from '../../components/countries/countries';
import PreFooter from '../../components/prefooter/prefooter';
import Footer from '../../components/footer/footer';
import VideoPlayer from '../../components/video-player/video-player';
import Container from '../../components/app/container/container';
import Faq from '../../components/faq/faq';
import FarewellModal from 'components/modals/FarewellModal/FarewellModal';
import { useState } from 'react';
import { Mission } from 'components/mission';
import { Format } from 'components/format';
import { History } from 'components/history';
import StatLines from 'components/StatLines/StatLines';
import { useTranslation } from 'react-i18next';
import { Globus } from 'components/globus';
import StartPack from 'components/StartPack/StartPack';
import { Plan } from 'components/plan';

function MainScreen() {
  const [isOpenFarewell, setIsOpenFarewell] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <PageTop />
      <Mission />
      <Format />
      <div className={styles.bg}>
        <Container>
          <div className={styles.wraptitle}>
            <h2 className={styles.topTitle}><span className={styles.oneRun}>{t("pageTop.subtitle1")}</span> <br /> {t("pageTop.subtitle2")}</h2>
            <div className={styles.text}>{t("pageTop.text")}</div>
          </div>
          <VideoPlayer video="https://heroleague.ru/video/onerun2024.mp4" />
        </Container>

        <History />
        <StatLines />
        <Countries />
        <Globus />
        <StartPack />
        <Plan />
        <Faq />

        {/*<FarewellModal isShow={isOpenFarewell} onClose={() => setIsOpenFarewell(false)} />*/}
        <PreFooter />
        <Footer />
      </div>
    </>
  );
}

export default MainScreen;
