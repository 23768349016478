import { v4 as uuidv4 } from 'uuid';

const faqCategories = [
  {
    id: uuidv4(),
    label: {
      ru: 'О мероприятии',
      en: 'ABOUT THE EVENT',
      es: 'SOBRE EL EVENTO',
      fr: "À PROPOS DE L'ÉVÉNEMENT",
      zh: '关于赛事',
      pt: 'SOBRE O EVENTO', // Added Portuguese translation
    },
    category: 'about',
  },
  {
    id: uuidv4(),
    label: {
      ru: 'До мероприятия',
      en: 'BEFORE THE EVENT',
      es: 'ANTES DEL ACONTECIMIENTO',
      fr: "AVANT L'ÉVÉNEMENT",
      zh: '赛前事项',
      pt: 'ANTES DO EVENTO', // Added Portuguese translation
    },
    category: 'before',
  },
  {
    id: uuidv4(),
    label: {
      ru: 'Во время мероприятия',
      en: 'DURING THE EVENT',
      es: 'DURANTE EL EVENTO',
      fr: "PENDANT L'ÉVÉNEMENT",
      zh: '活动期间',
      pt: 'DURANTE O EVENTO', // Added Portuguese translation
    },
    category: 'during',
  },
];

const faqArr = [
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Что такое Международный полумарафон «One Run»?',
        answer: (
          <>
            <p>
              Забег.РФ – ежегодное масштабное легкоатлетическое мероприятие,
              которое впервые состоялось 21 мая 2017 года. С тех пор география
              расширялась, и количество участников проекта постоянно растет.
              Полумарафон дважды вошел в Книгу рекордов Гиннесса, как крупнейший
              полумарафон с синхронным стартом в мире в 2020 и 2021 годах.
            </p>
            <p>
              В 2023 году полумарафон стал международным и под брендом «One Run»
              прошел в Китайской Народной Республике, Республике Беларусь,
              Республике Таджикистан и Республике Кыргызстан. Благодаря этому
              полумарафон «One Run» стал ведущим беговым событием в мире.
            </p>
            <p>
              В 2024 году полумарафон «One Run» объединил 15 стран, а именно:
              Россию, Китай, Кыргызстан, Таджикистан, Беларусь, Бразилию, Катар,
              Камерун, Сербию, Узбекистан, Непал, Египет, Армению, Бахрейн,
              Пакистан и собрал более 30 000 участников, став ведущим беговым событием в мире.
            </p>
          </>
        ),
      },
      en: {
        question: 'What is the «One Run» International Half Marathon?',
        answer: (
          <>
            <p>
              «One Run» is an annual large-scale track and field event, which
              first took place on May 21, 2017. Since then, we have expanded our
              geographical reach and rapidly increased our participant numbers.
              The half marathon has been included twice in the Guinness Book of
              Records as the largest half marathon with a synchronized start in
              the world in 2020 and 2021.
            </p>
            <p>
              In 2023, the Half Marathon became an international event and was
              held in the People's Republic of China, the Republic of Belarus,
              the Republic of Armenia, the Republic of Tajikistan and the
              Republic of Kyrgyzstan under the «One Run» brand. As a result, the
              «One Run» Half Marathon has become the leading cross-country
              running event in the world.
            </p>
            <p>
              The 2024 One Run Half Marathon united 15 countries, namely: China,
              Kyrgyzstan, Tajikistan, Belarus, Brazil, Qatar, Cameroon, Serbia,
              Uzbekistan, Nepal, Egypt, Armenia, Pakistan and attracted more
              than 30,000 participants, becoming the leading cross-country
              running event in the world.
            </p>
          </>
        ),
      },
      es: {
        question: '¿Qué es el Medio Maratón Internacional One Run?',
        answer: (
          <>
            <p>
              One Run es un evento anual de gran escala de atletismo, que tuvo
              lugar por primera vez el 21 de mayo de 2017. Desde entonces, hemos
              ampliado nuestro alcance geográfico y aumentado rápidamente el
              número de participantes. El medio maratón ha sido incluido dos
              veces en el Libro Guinness de los Récords como el medio maratón
              más grande con un inicio sincronizado en el mundo en 2020 y 2021.
            </p>
            <p>
              En 2023, el Medio Maratón se convirtió en un evento internacional
              y se celebró en China, Bielorrusia, Armenia, Tayikistán y
              Kirguistán bajo la marca One Run.El Medio Maratón One Run de 2024
              unió a 15 países, a saber: China, Kirguistán, Tayikistán,
              Bielorrusia, Brasil, Catar, Camerún, Serbia, Uzbekistán, Nepal,
              Egipto, Armenia, Pakistán, y atrajo a más de 30,000 participantes,
              convirtiéndose en el evento de carrera a campo traviesa líder en
              el mundo.
            </p>
            <p>
              El Medio Maratón One Run de 2024 unió a 15 países, a saber: China,
              Kirguistán, Tayikistán, Bielorrusia, Brasil, Catar, Camerún, Serbia,
              Uzbekistán, Nepal, Egipto, Armenia, Pakistán, y atrajo a más de 30,000
              participantes, convirtiéndose en el evento de carrera a campo traviesa líder en el mundo.
            </p>
          </>
        ),
      },
      fr: {
        question: "Qu'est-ce que le semi-marathon international One Run?",
        answer: (
          <>
            <p>
              One Run est un événement annuel d'athlétisme à grande échelle, qui
              a eu lieu pour la première fois le 21 mai 2017. Depuis, nous avons
              élargi notre portée géographique et augmenté rapidement le nombre
              de participants. Le semi-marathon a été inclus deux fois dans le
              Livre Guinness des records en tant que plus grand semi-marathon
              avec départ synchronisé au monde en 2020 et 2021.
            </p>
            <p>
              En 2023, le semi-marathon est devenu un événement international et
              a été organisé en Chine, en Biélorussie, en Arménie, au
              Tadjikistan et au Kirghizistan sous la marque One Run. Le
              semi-marathon One Run de 2024 a réuni 15 pays, à savoir: Chine,
              Kirghizistan, Tadjikistan, Biélorussie, Brésil, Qatar, Cameroun,
              Serbie, Ouzbékistan, Népal, Égypte, Arménie, Pakistan et a attiré
              plus de 30 000 participants, devenant ainsi le principal événement
              de course à pied cross-country au monde.
            </p>
            <p>
              Le semi-marathon One Run de 2024 a réuni 15 pays, à savoir: Chine,
              Kirghizistan, Tadjikistan, Biélorussie, Brésil, Qatar, Cameroun,
              Serbie, Ouzbékistan, Népal, Égypte, Arménie, Pakistan et a attiré plus
              de 30 000 participants, devenant ainsi le principal événement de course
              à pied cross-country au monde.
            </p>
          </>
        ),
      },
      zh: {
        question: '“一起跑”国际半程马拉松是一个怎样的赛事？',
        answer: (
          <>
            <p>
              “一起跑
              ”是一年一度的大型田径赛事，于2017年5月21日首次举办。自此，我们的地理范围不断扩大，参赛人数迅速增加。2020
              年和 2021
              年，该半程马拉松作为世界上同步起跑规模最大的半程马拉松赛事两次被载入吉尼斯世界纪录。
            </p>
          </>
        ),
      },
      pt: {
        question: 'O que é a Meia Maratona Internacional One Run?',
        answer: (
          <>
            <p>
              One Run é um evento anual de atletismo em grande escala, que ocorreu pela primeira vez em 21 de maio de 2017. Desde então, expandimos nosso alcance geográfico e aumentamos rapidamente o número de participantes. A meia maratona foi incluída duas vezes no Guinness World Records como a maior meia maratona com início sincronizado no mundo em 2020 e 2021.
            </p>
            <p>
              Em 2023, a Meia Maratona tornou-se um evento internacional e foi realizada na China, Bielorrússia, Armênia, Tajiquistão e Quirguistão sob a marca One Run. A Meia Maratona One Run de 2024 reuniu 15 países, a saber: China, Quirguistão, Tajiquistão, Bielorrússia, Brasil, Catar, Camarões, Sérvia, Uzbequistão, Nepal, Egito, Armênia, Paquistão, e atraiu mais de 30.000 participantes, tornando-se o principal evento de corrida cross-country do mundo.
            </p>
            <p>
              A Meia Maratona One Run de 2024 reuniu 15 países, a saber: China, Quirguistão, Tajiquistão, Bielorrússia, Brasil, Catar, Camarões, Sérvia, Uzbequistão, Nepal, Egito, Armênia, Paquistão, e atraiu mais de 30.000 participantes, tornando-se o principal evento de corrida cross-country do mundo.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какая особенность Международного полумарафона «One Run»?',
        answer: (
          <p>
            Уникальной особенностью полумарафона является старт во всех
            городах-участниках в единый день.
          </p>
        ),
      },
      en: {
        question:
          'What is the unique feature of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The unique feature of our half-marathon is the start which is held
            in all participating cities on the same day.
          </p>
        ),
      },
      es: {
        question:
          '¿Cuál es la característica especial del Medio Maratón Internacional One Run?',
        answer: (
          <p>
            Una característica única del Medio Maratón es que todas las ciudades
            participantes empiezan el mismo día.
          </p>
        ),
      },
      fr: {
        question:
          'Quelle est la particularité du One Run International Half Marathon ?',
        answer: (
          <p>
            La particularité du semi-marathon est que le départ est donné le
            même jour dans toutes les villes participantes.
          </p>
        ),
      },
      zh: {
        question: '“一起跑”国际半程马拉松赛的特色是什么？',
        answer: (
          <p>
            我们半程马拉松的独特之处在于所有参赛城市都在同一天举行起跑仪式。
          </p>
        ),
      },
      pt: {
        question: 'Qual é a característica única da Meia Maratona Internacional One Run?',
        answer: (
          <p>
            Uma característica única da nossa meia maratona é que a largada ocorre no mesmo dia em todas as cidades participantes.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какие цели Международного полумарафона «One Run»?',
        answer: (
          <p>
            Цель «One Run» - популяризация спорта среди населения, укрепление
            партнерских и дружеских взаимоотношений, а также культурный обмен
            между странами. Мы собираем на наших мероприятиях единомышленников,
            которые любят спорт и активный досуг, и стремимся к тому, чтобы
            массовый любительский спорт был доступен в каждой стране.
          </p>
        ),
      },
      en: {
        question:
          'What are the goals of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The goal of «One Run» is to popularize sports, strengthen
            partnerships and amicable relations, as well as encourage the
            exchange of cultures between our participating countries. At our
            events we gather like-minded people who are passionate about sports
            and active leisure, and strive to make mass amateur sports available
            in every country.
          </p>
        ),
      },
      es: {
        question:
          '¿Cuáles son los objetivos de la Media Maratón Internacional One Run?',
        answer: (
          <p>
            El objetivo de One Run es popularizar el deporte entre la población,
            reforzar la asociación y las relaciones amistosas, así como el
            intercambio cultural entre países. En nuestros eventos reunimos a
            personas con ideas afines que aman el deporte y el ocio activo, y
            nos esforzamos por que el deporte aficionado de masas esté
            disponible en todos los países.
          </p>
        ),
      },
      fr: {
        question:
          'Quels sont les objectifs du semi-marathon international One Run ?',
        answer: (
          <p>
            L'objectif de One Run est de populariser le sport auprès de la
            population, de renforcer les partenariats et les relations amicales,
            ainsi que les échanges culturels entre les pays. Nous rassemblons
            des personnes partageant les mêmes idées et aimant le sport et les
            loisirs actifs lors de nos événements et nous nous efforçons de
            rendre le sport amateur de masse accessible dans tous les pays.
          </p>
        ),
      },
      zh: {
        question: '“一起跑”国际半程马拉松赛的目标是什么？',
        answer: (
          <p>
            “一起跑”的目标是普及体育运动，加强伙伴关系和友好关系，以及鼓励参与国之间的文化交流。在赛事中，我们聚集了热爱体育和积极休闲的志同道合者，并努力在每个国家普及大众业余体育运动。
          </p>
        ),
      },
      pt: {
        question: 'Quais são os objetivos da Meia Maratona Internacional One Run?',
        answer: (
          <p>
            O objetivo do One Run é popularizar o esporte, fortalecer parcerias e relações amigáveis, além de incentivar a troca de culturas entre os países participantes. Em nossos eventos, reunimos pessoas com ideias semelhantes que são apaixonadas por esportes e lazer ativo, e nos esforçamos para tornar o esporte amador de massa disponível em todos os países.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question:
          'Какие ценности и принципы Международного полумарафона «One Run»?',
        answer: (
          <>
            <p>
              Объединение. Мы собираем на наших мероприятиях единомышленников,
              которые любят спорт и активный досуг.
            </p>
            <p>
              Доступность. Мы стремимся к тому, чтобы массовый любительский
              спорт был доступен для широких масс и не требовал больших
              финансовых затрат.
            </p>
            <p>
              Здоровье. Мы качественно улучшаем жизнь, осознанно выбирая ЗОЖ, а
              также позитивно влияем на общество.
            </p>
          </>
        ),
      },
      en: {
        question: 'What are the values and principles of «One Run»?',
        answer: (
          <>
            <p>
              Unification. We bring together like-minded people who are
              passionate about sports and outdoor activities at our events.
            </p>
            <p>
              Accessibility. We strive to make mass amateur sports accessible to
              the masses and not requiring large financial outlays.
            </p>
            <p>
              Health. We qualitatively improve life by consciously choosing
              healthy lifestyles, as well as positively influence society.
            </p>
          </>
        ),
      },
      es: {
        question:
          '¿Cuáles son los valores y principios de la Media Maratón Internacional One Run?',
        answer: (
          <>
            <p>
              Unificación. Reunimos en nuestros eventos a personas afines que
              aman el deporte y el ocio activo.
            </p>
            <p>
              Accesibilidad. Nos esforzamos por hacer que el deporte amateur de
              masas sea accesible a las masas y no requiera grandes desembolsos
              económicos.
            </p>
            <p>
              Salud. Mejoramos cualitativamente la vida eligiendo
              conscientemente estilos de vida saludables, además de influir
              positivamente en la sociedad.
            </p>
          </>
        ),
      },
      fr: {
        question:
          'Quels sont les valeurs et les principes du One Run International Half Marathon?',
        answer: (
          <>
            <p>
              L'unification. Nous rassemblons lors de nos événements des
              personnes partageant les mêmes idées et aimant le sport et les
              loisirs actifs.
            </p>
            <p>
              Accessibilité. Nous nous efforçons de rendre le sport amateur de
              masse accessible au plus grand nombre et ne nécessitant pas de
              grandes dépenses financières.
            </p>
            <p>
              La santé. Nous améliorons la qualité de la vie en choisissant
              consciemment des modes de vie sains, et nous influençons
              positivement la société.
            </p>
          </>
        ),
      },
      zh: {
        question: '“一起跑”的价值观和原则是什么？',
        answer: (
          <>
            <p>团结。 我们在活动中聚集热爱体育和户外活动的志同道合者。</p>
            <p>
              普及性。 我们致力于让大众业余体育变得触手可及，参与者无需大量经济投入。
            </p>
            <p>
              健康。 我们通过有意识地选择健康的生活方式，提升生活质量，并积极影响社会。
            </p>
          </>
        ),
      },
      pt: {
        question: 'Quais são os valores e princípios do One Run?',
        answer: (
          <>
            <p>
              Unificação. Reunimos pessoas com ideias semelhantes que são apaixonadas por esportes e atividades ao ar livre em nossos eventos.
            </p>
            <p>
              Acessibilidade. Nos esforçamos para tornar o esporte amador de massa acessível a todos, sem exigir grandes gastos financeiros.
            </p>
            <p>
              Saúde. Melhoramos qualitativamente a vida ao escolher conscientemente estilos de vida saudáveis e influenciamos positivamente a sociedade.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question:
          'Какие дистанции доступны на Международном полумарафоне «One Run»?',
        answer: (
          <p>
            Доступны для прохождения четыре дистанции – 1 км, 5 км, 10 км и 21,1
            км.
          </p>
        ),
      },
      en: {
        question:
          'What distances are available at the «One Run» International Half Marathon?',
        answer: (
          <p>
            Participants of the event have a choice of four distances to run -
            1km, 5km, 10km and 21.1km.
          </p>
        ),
      },
      es: {
        question:
          '¿Qué distancias hay disponibles en el Medio Maratón Internacional One Run?',
        answer: (
          <p>Hay cuatro distancias disponibles: 1 km, 5 km, 10 km y 21,1 km.</p>
        ),
      },
      fr: {
        question:
          'Quelles sont les distances disponibles pour le semi-marathon international One Run?',
        answer: (
          <p>Il y a quatre distances disponibles - 1km, 5km, 10km et 21,1km.</p>
        ),
      },
      zh: {
        question: '“一起跑”国际半程马拉松赛有哪些距离可供选择？',
        answer: (
          <p>参赛者可以选择 1 公里、5 公里、10 公里和 21.1 公里这四个距离。</p>
        ),
      },
      pt: {
        question: 'Quais distâncias estão disponíveis na Meia Maratona Internacional One Run?',
        answer: (
          <p>Há quatro distâncias disponíveis: 1 km, 5 km, 10 km e 21,1 km.</p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'От чего зависит стоимость участия?',
        answer: (
          <p>
            На ранних этапах регистрации Вам предоставляется скидка. И именно
            объем скидки уменьшается с приближением даты старта. Величина
            целевого взноса формируется с учетом стоимости проведения
            мероприятия в отдельном городе, поэтому в каждой стране разные цены.
          </p>
        ),
      },
      en: {
        question: 'What does the cost of participation depend on?',
        answer: (
          <p>
            You get a discount in the early stages of registration. And it is
            the amount of the discount that decreases as the start date
            approaches. The amount of the target fee is formed taking into
            account the cost of the event in the individual city, so each
            country has different prices.
          </p>
        ),
      },
      es: {
        question: '¿De qué depende el coste de la participación?',
        answer: (
          <p>
            En las primeras fases de la inscripción se le aplica un descuento. Y el importe del descuento disminuye a medida que se acerca la fecha de inicio. El importe de la cuota objetivo se basa en el coste de organización del evento en una ciudad concreta, por lo que cada país tiene precios diferentes.
          </p>
        ),
      },
      fr: {
        question: 'De quoi dépend le coût de la participation ?',
        answer: (
          <p>
            Lors des premières étapes de l'inscription, vous bénéficiez d'une réduction. Le montant de cette réduction diminue au fur et à mesure que la date de début de l'événement approche. Le montant des frais d'inscription est basé sur le coût de l'organisation de l'événement dans une ville donnée, de sorte que les prix varient d'un pays à l'autre.
          </p>
        ),
      },
      zh: {
        question: '参赛费用收费标准是什么？',
        answer: (
          <p>
            在报名的早期阶段，您可以享受折扣。而且，随着活动开始日期的临近，折扣金额会逐渐减少。参赛金额是根据各城市举办赛事的成本决定的，因此每个国家的价格都不相同。
          </p>
        ),
      },
      pt: {
        question: 'Do que depende o custo de participação?',
        answer: (
          <p>
            Você recebe um desconto nas primeiras etapas de inscrição. E o valor do desconto diminui à medida que a data de início se aproxima. O valor da taxa é formado com base no custo do evento em cada cidade, portanto, cada país tem preços diferentes.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Какие возрастные ограничения предусмотрены?',
        answer: (
          <>
            <p>
              К участию в «One Run» на дистанцию 1 км допускаются все желающие в
              возрасте от 6 лет и старше. Дети 6-12 лет бегут только в
              сопровождении родителей.
            </p>
            <p>
              К участию в «One Run» на дистанцию 5 км допускаются все желающие
              от 16 лет и старше.
            </p>
            <p>
              К участию в «One Run» на дистанцию 10 и 21,1 км допускаются все
              желающие от 18 лет и старше.
            </p>
            <p>
              Возрастные ограничения строго соблюдаются, независимо от уровня
              подготовки участника или наличия каких-либо дополнительных
              документов/разрешений. Это связано с тем, что мероприятие является
              любительским и массовым и в первую очередь направлено на получение
              удовольствия от процесса, а не на проверку себя и своей
              выносливости.
            </p>
          </>
        ),
      },
      en: {
        question: 'What are the age limitations?',
        answer: (
          <>
            <p>
              Everyone aged 6 years and older is allowed to participate in the 1
              km «One Run». Children 6-12 years old run only if accompanied by
              their parents.
            </p>
            <p>
              To participate in the «One Run» at a distance of 5 km are allowed
              all comers from 16 years and older.
            </p>
            <p>
              Everyone from 18 years of age and older is allowed to participate
              in the «One Run» 10 and 21.1 km.
            </p>
            <p>
              Age restrictions are strongly enforced regardless of the
              participant's fitness level or any additional documents/permits.
              This is due to the fact that the event is an amateur and mass
              event and is primarily aimed at enjoying the process, rather than
              testing oneself and one's endurance.
            </p>
          </>
        ),
      },
      es: {
        question: '¿Cuáles son las restricciones de edad?',
        answer: (
          <>
            <p>
              La carrera One Run de 1 km está abierta a todos los mayores de 6 años. Los niños de 6 a 12 años sólo pueden correr si van acompañados de sus padres.
            </p>
            <p>
              La One Run de 5 km está abierta a todos los mayores de 16 años.
            </p>
            <p>
              Las carreras One Run de 10 y 21,1 kilómetros están abiertas a todos los mayores de 18 años.
            </p>
            <p>
              Las restricciones de edad se respetan estrictamente, independientemente del nivel de forma física del participante o de cualquier documento/permiso adicional. Esto se debe al hecho de que el evento es amateur y masivo, y su objetivo principal es disfrutar del proceso más que ponerse a prueba a uno mismo y su resistencia.
            </p>
          </>
        ),
      },
      fr: {
        question: 'Quelles sont les restrictions d\'âge ?',
        answer: (
          <>
            <p>
              La course One Run de 1 km est ouverte à toutes les personnes âgées de 6 ans et plus. Les enfants de 6 à 12 ans ne peuvent courir que s'ils sont accompagnés de leurs parents.
            </p>
            <p>
              La course unique de 5 km est ouverte à toutes les personnes âgées de 16 ans et plus.
            </p>
            <p>
              Les courses de 10 et 21,1 kilomètres sont ouvertes aux personnes âgées de 18 ans et plus.
            </p>
            <p>
              Les restrictions d'âge sont strictamente appliquées, quel que soit le niveau de forme physique du participant ou tout autre document/permis supplémentaire. Ceci est dû au fait que l'événement est un événement amateur et de masse et qu'il vise principalement à profiter du processus plutôt qu'à se tester et à tester son endurance.
            </p>
          </>
        ),
      },
      zh: {
        question: '有哪些年龄限制？',
        answer: (
          <>
            <p>
              所有6岁及以上的群众都可以参加1公里的“一起跑”赛事活动。6至12岁的儿童须在父母陪同下跑步。
            </p>
            <p>
              16岁及以上可以参加5公里的“一起跑”比赛。
            </p>
            <p>
              18岁及以上的群众可以参加10公里和21.1公里的“一起跑”比赛。
            </p>
            <p>
              无论参与者的身体素质如何、是否持有任何额外文件证明/许可，都必须严格遵守年龄限制。因为该赛事是业余性和大众性的，其主要目的是享受过程，而非测试个人的耐力或体力极限。
            </p>
          </>
        ),
      },
      pt: {
        question: 'Quais são as restrições de idade?',
        answer: (
          <>
            <p>
              Todos com 6 anos ou mais podem participar da corrida de 1 km do One Run. Crianças de 6 a 12 anos só podem correr se acompanhadas pelos pais.
            </p>
            <p>
              A corrida de 5 km do One Run está aberta a todos com 16 anos ou mais.
            </p>
            <p>
              As corridas de 10 km e 21,1 km do One Run estão abertas a todos com 18 anos ou mais.
            </p>
            <p>
              As restrições de idade são rigorosamente aplicadas, independentemente do nível de condicionamento físico do participante ou de qualquer documento/permissão adicional. Isso ocorre porque o evento é amador e de massa, visando principalmente o prazer do processo, e não a testar a resistência ou os limites físicos.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Как узнать расписание стартов?',
        answer: (
          <p>
            Точное расписание мероприятия в вашем городе будет опубликовано на
            сайте мероприятия в каждой стране ближе к дате проведения.
          </p>
        ),
      },
      en: {
        question: 'How do I find out the schedule of starts?',
        answer: (
          <p>
            The exact schedule of the event in your city will be published on
            the event website in each country closer to the date of the event.
          </p>
        ),
      },
      es: {
        question: '¿Cómo puedo saber el horario de salida?',
        answer: (
          <p>
            El horario exacto del evento en su ciudad se publicará en el sitio web del evento en cada país más cerca de la fecha del evento.
          </p>
        ),
      },
      fr: {
        question: 'Comment connaître l\'horaire de départ ?',
        answer: (
          <p>
            L'horaire exact de l'événement dans votre ville sera publié sur le site web de l'événement dans chaque pays à l'approche de la date de l'événement.
          </p>
        ),
      },
      zh: {
        question: '如何查询起跑时间表？',
        answer: (
          <p>
            您所在城市的确切活动安排将在临近活动日期时公布在各国的赛事网站上。
          </p>
        ),
      },
      pt: {
        question: 'Como posso saber o horário de início?',
        answer: (
          <p>
            O cronograma exato do evento em sua cidade será publicado no site do evento em cada país, mais próximo da data do evento.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Как узнать маршрут?',
        answer: (
          <p>Маршрут будет опубликован на сайте мероприятия в каждой стране.</p>
        ),
      },
      en: {
        question: 'How do I find out the route?',
        answer: (
          <p>The routes will be published on each country's event website.</p>
        ),
      },
      es: {
        question: '¿Cómo puedo conocer el itinerario?',
        answer: (
          <p>El itinerario se publicará en el sitio web del evento en cada país.</p>
        ),
      },
      fr: {
        question: 'Comment connaître l\'itinéraire?',
        answer: (
          <p>L'itinéraire sera publié sur le site web de l'événement dans chaque pays.</p>
        ),
      },
      zh: {
        question: '如何查询路线？',
        answer: (
          <p>路线将公布在各国的赛事网站上。</p>
        ),
      },
      pt: {
        question: 'Como posso saber o itinerário?',
        answer: (
          <p>O itinerário será publicado no site do evento em cada país.</p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Предусмотрены ли камеры хранения и раздевалки?',
        answer: (
          <p>
            В каждом городе предусмотрены раздевалки и камера хранения для
            участников. Мы рекомендуем приезжать заранее в спортивной форме,
            чтобы не тратить время на переодевание. Не сдавайте в пакете ценные
            или хрупкие вещи. Мы советуем взять ценные вещи с собой или оставить
            у тех, кто вас сопровождает.
          </p>
        ),
      },
      en: {
        question: 'Are locker rooms and changing rooms provided?',
        answer: (
          <p>
            In each city there are locker rooms and a storage room for
            participants. We recommend arriving in advance in your sports
            uniform to avoid wasting time changing clothes. Do not bring
            valuable or fragile items in a bag. We advise you to take valuables
            with you or leave them with those who accompany you.
          </p>
        ),
      },
      es: {
        question: '¿Hay vestuarios y taquillas?',
        answer: (
          <p>
            Cada ciudad dispone de vestuarios y almacenes para los participantes. Le recomendamos que llegue pronto con su uniforme deportivo para no tener que perder tiempo cambiándose. No lleve objetos valiosos o frágiles en una bolsa. Le aconsejamos que lleve consigo objetos de valor o que los deje con sus compañeros de viaje.
          </p>
        ),
      },
      fr: {
        question: 'Y a-t-il des vestiaires et des cabines de déshabillage ?',
        answer: (
          <p>
            Chaque ville dispose de vestiaires et de rangements pour les participants. Nous vous recommandons d'arriver tôt en tenue de sport afin de ne pas perdre de temps à vous changer. N'apportez pas d'objets fragiles ou de valeur dans un sac. Nous vous conseillons d'emporter vos objets de valeur ou de les confier à vos compagnons de voyage.
          </p>
        ),
      },
      zh: {
        question: '是否提供储物间和更衣室？',
        answer: (
          <p>
            每个城市都设有储物间和更衣室，供参与者使用。我们建议您穿着运动服提前到达，以避免浪费换衣服的时间。请勿将贵重物品或易碎物品装入包中。我们建议您将贵重物品随身携带或留给陪同人员。
          </p>
        ),
      },
      pt: {
        question: 'Há vestiários e armários?',
        answer: (
          <p>
            Cada cidade tem vestiários e depósitos para os participantes. Recomendamos que chegue cedo com seu uniforme esportivo para não perder tempo se trocando. Não traga objetos de valor ou frágeis em uma bolsa. Aconselhamos que leve objetos de valor consigo ou os deixe com seus acompanhantes.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Существуют ли пит-стопы на трассе?',
        answer: (
          <p>
            На протяжении всей трассы будут расположены пит-стопы с водой и
            фруктами, чтобы освежиться и подзарядиться.
          </p>
        ),
      },
      en: {
        question: 'Are there pit stops on the route?',
        answer: (
          <p>
            There will be pit stops throughout the route with water and fruit to
            refresh and recharge.
          </p>
        ),
      },
      es: {
        question: '¿Hay paradas en boxes en la pista?',
        answer: (
          <p>
            Habrá paradas en boxes a lo largo de la pista con agua y fruta para refrescarse y reponer fuerzas.
          </p>
        ),
      },
      fr: {
        question: 'Y a-t-il des arrêts au stand sur la piste ?',
        answer: (
          <p>
            Il y aura des arrêts au stand tout au long de la piste avec de l'eau et des fruits pour se rafraîchir et se ressourcer.
          </p>
        ),
      },
      zh: {
        question: '路线上有休息站吗？',
        answer: (
          <p>
            整个路线上都设有中途休息站，提供水和水果以补充体力。
          </p>
        ),
      },
      pt: {
        question: 'Há postos de abastecimento no percurso?',
        answer: (
          <p>
            Haverá postos de abastecimento ao longo do percurso com água e fruta para se refrescar e recarregar as energias.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Что делать, если со мной что-то случится на мероприятии?',
        answer: (
          <p>
            На каждой трассе будут работать машины скорой помощи. На старте и
            финише будет также расположен медицинский пункт. Пожалуйста, не
            стесняйтесь обращаться за помощью, если почувствуете себя плохо или
            заметите, что стало плохо другому участнику. В экстренном случае
            обращайтесь к волонтёрам или судьям, привлеките внимание окружающих
            и вызовите медицинскую помощь.
          </p>
        ),
      },
      en: {
        question: 'What if something happens to me at the event?',
        answer: (
          <p>
            Ambulances will be available on each route. There will also be a
            medical station at the start and finish line. Please do not hesitate
            to seek help if you feel unwell or notice that another competitor
            has become unwell. In case of emergency, ask for volunteers or
            judges, attract the attention of others and call for medical help.
          </p>
        ),
      },
      es: {
        question: '¿Qué pasa si me ocurre algo en el evento?',
        answer: (
          <p>
            Habrá ambulancias en cada recorrido. También habrá un centro médico en la línea de salida y de meta. No dude en pedir ayuda si se encuentra mal o si observa que otro competidor se encuentra mal. En caso de emergencia, diríjase a los voluntarios o a los jueces, atraiga la atención de los demás y pida ayuda médica.
          </p>
        ),
      },
      fr: {
        question: 'Que se passe-t-il si quelque chose m\'arrive pendant l\'événement ?',
        answer: (
          <p>
            Il y aura des ambulances sur chaque parcours. Il y aura également un centre médical à la ligne de départ et d'arrivée. N'hésitez pas à appeler à l'aide si vous vous sentez mal ou si vous remarquez qu'un autre concurrent se sent mal. En cas d'urgence, allez vers les bénévoles ou les juges, attirez l'attention des autres et appelez l'aide médicale.
          </p>
        ),
      },
      zh: {
        question: '如果我在比赛过程中发生意外怎么办？',
        answer: (
          <p>
            每条路线上都有救护车。起点和终点处还将设有医疗站。如果您感觉不适或发现其他参赛者身体不适，请立即寻求帮助。在紧急情况下，请求助志愿者或裁判，向他人呼救，并呼叫医疗帮助。
          </p>
        ),
      },
      pt: {
        question: 'O que acontece se algo me acontecer no evento?',
        answer: (
          <p>
            Haverá ambulâncias em cada percurso. Também haverá um posto médico na linha de partida e chegada. Por favor, não hesite em pedir ajuda se se sentir mal ou notar que outro competidor se sente mal. Em caso de emergência, dirija-se aos voluntários ou juízes, chame a atenção de outros e peça ajuda médica.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Могу ли я привести зрителей на мероприятие?',
        answer: (
          <p>
            Можно приходить всей семьей и с друзьями любого возраста, чтобы
            поддержать спортсменов на трассе и финише. К тому же для всех гостей
            мероприятия будут подготовлены активности и развлечения.
          </p>
        ),
      },
      en: {
        question: 'Can I bring spectators to the event?',
        answer: (
          <p>
            You can come with your family and friends of all ages to support the
            athletes on the route and at the finish line. In addition, there
            will be activities and entertainment for all guests of the event.
          </p>
        ),
      },
      es: {
        question: '¿Puedo llevar espectadores al evento?',
        answer: (
          <p>
            Toda la familia y amigos de todas las edades están invitados a venir a apoyar a los atletas en el recorrido y en la línea de meta. Además, habrá actividades y entretenimiento para todos los invitados al evento.
          </p>
        ),
      },
      fr: {
        question: 'Puis-je amener des spectateurs à l\'événement ?',
        answer: (
          <p>
            Toute la famille et les amis de tous âges sont les bienvenus pour soutenir les athlètes sur le parcours et sur la ligne d'arrivée. En outre, des activités et des divertissements seront organisés pour tous les invités de l'événement.
          </p>
        ),
      },
      zh: {
        question: '我可以带观众来比赛现场吗？',
        answer: (
          <p>
            您可以与您的家人和各年龄段的朋友一起，在整条路线上、在终点为运动员加油助威。此外，活动期间还将为所有来宾提供活动和娱乐项目。
          </p>
        ),
      },
      pt: {
        question: 'Posso trazer espectadores para o evento?',
        answer: (
          <p>
            Toda a família e amigos de todas as idades são bem-vindos para apoiar os atletas no percurso e na linha de chegada. Além disso, haverá atividades e entretenimento para todos os convidados do evento.
          </p>
        ),
      },
    },
  },
];

export { faqCategories, faqArr };
